body{
  background-color: #dedddd !important;
}
.ten-height{
  height: 10vh;
}
.second-height{
  height: 20vh;
}
.third-height{
  height: 30vh;
}
.half-height{
  height: 50vh;
}
.eight-height{
  height: 82vh;
}
.nine-height{
  height: 90vh;
}
.total-height{
  height: 100vh;
}
.total-width{
  width: 100vh;
}
.text-align-center{
  text-align: center;
}
.bg-with{
  background-color: #fff;
}
.bg-black{
  background-color: #000;
}
.bg-green{
  background-color: #0fab23 !important;
}
.bg-red{
  background-color: #f00;
}
.bg-blue{
  background-color: #2d30dc !important;
}
.text-white{
  color: #FFF !important;
}
.vertical-over{
  overflow-y: auto !important;
}
.bold{
  font-weight: bold !important;
}
.footer-option-menu{
  height: 9vh;
  padding-top: 1.5vh;
  cursor: pointer;
  font-weight: bold;
  background-color: #dedddd !important;
}
.footer-option-menu:hover{
  background-color: #eee !important;
}
.footer-option-icon{
  font-size: 24px;
}
.footer-option-active{
  background-color: #333 !important;
  color: #fff !important;
}
.text-capitalize{
  text-transform: capitalize;
}
.cursor{
  cursor: pointer;
}
.text-align-right{
  text-align: right;
}
.text-align-left{
  text-align: left;
}
.font-size-11{
  font-size: 11px !important;
}
.font-size-12{
  font-size: 12px !important;
}
.font-size-14{
  font-size: 14px !important;
}
.font-size-16{
  font-size: 16px !important;
}
.font-size-18{
  font-size: 18px !important;
}
.font-size-24{
  font-size: 24px !important;
}
.font-size-32{
  font-size: 32px !important;
}
.font-size-46{
  font-size: 46px !important;
}
.player-option{
  font-weight: bold;
  text-decoration: underline;  
}
.selected{
  color: #000;
  background-color: #d0d0d0;
  font-weight: bold;
}
.content-data{
  height: 30vh;
  overflow-y: auto;
}